import "./styles.scss"

import React from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"

import mapStyle from "../../../libs/mapStyle"
import MarkerIcon from "assets/icons/marker.svg"

const containerStyle = {
  width: "100%",
  height: "420px",
}

const center = {
  lat: 49.481,
  lng: 20.023,
}

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC2WSNpiw4o2WYVIS944mHvnmVsNaZVVzg",
  })

  return (
    <section className="map">
      <div className="container">
        <div className="map__wrapper">
          {isLoaded && (
            <GoogleMap
              center={center}
              zoom={15}
              options={{
                styles: mapStyle,
                disableDefaultUI: true,
              }}
              mapContainerStyle={containerStyle}
            >
              <Marker icon={MarkerIcon} position={center} />
            </GoogleMap>
          )}
        </div>
      </div>
    </section>
  )
}

export default Map
