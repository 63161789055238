import "./styles.scss"

import React from "react"

import Modal from "components/Modal"

const widgetSrcRender = type => {
  switch (type) {
    case "warszawa":
      return "//system.proassist.pl/widget-tourmedic/venue/view/ps-c583-130z6g/ps-v1685-x1lmd"
    default:
      return "//system.proassist.pl/widget-tourmedic/venue/view/ps-c583-130z6g/ps-v1151-f8r4u"
  }
}

const ContactModal = ({ type, handleClose }) => {
  const widget_src = widgetSrcRender(type)

  return (
    <div className="contact-modal">
      <Modal handleClose={handleClose}>
        <div className="contact-modal__widget">
          <iframe
            src={widget_src}
            width="320"
            height="496"
            frameborder="0"
            title="contact"
          ></iframe>
        </div>
      </Modal>
    </div>
  )
}

export default ContactModal
