import "./styles.scss"

import React from "react"
import Button from "components/Button"

const ContactInfo = ({ data, setContactModal }) => {
  const { address, addressBottom, opening } = data
  return (
    <section className="contact-info">
      <div className="container">
        <div className="contact-info__wrapper">
          <div className="row contact-info__row">
            <div className="col-lg-5 col-12">
              <div className="contact-info__col-1">
                <div className="contact-info__title">Skontaktuj się z nami</div>
                <div className="contact-info__phone">
                  <img
                    src={require("assets/icons/phone-black.svg").default}
                    alt=""
                  />
                  <a href="tel:0048500301420">+48 500 301 420</a>
                </div>
                {/* <p className="contact-info__small">
                  Dostępny w godzinach otwarcia
                </p> */}
                {/* <div className="contact-info__book">
                  <Button
                    type="button"
                    className="button-dark-green"
                    onClick={() => setContactModal(true)}
                  >
                    Umów wizytę online
                  </Button>
                  <p className="contact-info__small">
                    Dostępny w godzinach otwarcia
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="contact-info__col-2">
                <div className="contact-info__title">Adres Siedziby</div>
                <div
                  className="contact-info__address"
                  dangerouslySetInnerHTML={{ __html: address }}
                />
                <Button
                  type="button"
                  className="button-dark-green"
                  onClick={() => setContactModal("nowy-targ")}
                >
                  Umów wizytę online
                </Button>
              </div>
              <div className="contact-info__col-2">
                <div
                  className="contact-info__address"
                  dangerouslySetInnerHTML={{ __html: addressBottom }}
                />
                <Button
                  type="button"
                  className="button-dark-green"
                  onClick={() => setContactModal("warszawa")}
                >
                  Umów wizytę online
                </Button>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="contact-info__col-3">
                <p className="contact-info__title">Godziny otwarcia</p>
                <div
                  className="contact-info__opening"
                  dangerouslySetInnerHTML={{ __html: opening }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactInfo
